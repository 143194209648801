/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/iframe-has-title */
import { CloseTwoTone } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import useToggleState from '../../hooks/useToggleState';

import styles from './styles.module.css';

export function ChatAI() {
  const token = localStorage.getItem('token') ?? '';
  const client = localStorage.getItem('selectedClient');
  const isAdmin = localStorage.getItem('isAdmin');
  const { W, H, L, T } = JSON.parse(localStorage.getItem('Siria') || '{}');
  const dialogRef = useRef<any>(null);
  const sidebarRef = useRef<any>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(W || 400);
  const [sidebarHeight, setSidebarHeight] = useState(H || 500);
  const [sidebarTop, setSidebarTop] = useState(T || window.innerHeight - 550);
  const [sidebarLeft, setSidebarLeft] = useState(L || window.innerWidth - 410);
  const [memoLeft, setMemoLeft] = useState(0);
  const [memoTop, setMemoTop] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [open, toggle] = useToggleState(false);

  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const idFascicolo = parseInt(locationArray[2]);

  const handleOpen = useCallback(() => {
    setIsFullScreen(false);
    toggle();
  }, [toggle]);

  const handleFullscreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const handleRestore = useCallback(() => {
    setIsFullScreen(false);
    setSidebarLeft(window.innerWidth - 410);
    setSidebarTop(window.innerHeight - 550);
    setSidebarWidth(400);
    setSidebarHeight(500);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isFullScreen) {
        setSidebarLeft(0);
        setSidebarTop(0);
        setSidebarWidth(window.innerWidth - 5);
        setSidebarHeight(window.innerHeight - 5);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (isFullScreen) {
      setSidebarLeft(0);
      setSidebarTop(0);
      setSidebarWidth(window.innerWidth - 5);
      setSidebarHeight(window.innerHeight - 5);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { W, H, L, T } = JSON.parse(localStorage.getItem('Siria') || '{}');
      setSidebarLeft(L || window.innerWidth - 410);
      setSidebarTop(T || window.innerHeight - 550);
      setSidebarWidth(W || 400);
      setSidebarHeight(H || 500);
    }
  }, [isFullScreen]);

  const trasform = useCallback(
    (mouseMoveEvent: any) => {
      if (isFullScreen) return;

      if (mouseMoveEvent.buttons === 0) {
        setMemoLeft(mouseMoveEvent.clientX);
        setMemoTop(mouseMoveEvent.clientY);
      }

      if (sidebarRef.current && dialogRef.current) {
        if (isDrag && mouseMoveEvent.buttons === 1) {
          const dragLeft = (mouseMoveEvent.clientX - memoLeft) + sidebarLeft;
          const dragTop = (mouseMoveEvent.clientY - memoTop) + sidebarTop;
          setSidebarLeft(dragLeft);
          setSidebarTop(dragTop);
        }

        if (isResizing && mouseMoveEvent.buttons === 1) {
          const resizeWidth = (mouseMoveEvent.clientX - sidebarLeft + 6);
          const resizeHeight = (mouseMoveEvent.clientY - sidebarTop);
          setSidebarWidth(resizeWidth);
          if (resizeWidth < 350)setSidebarWidth(350);
          setSidebarHeight(resizeHeight);
          if (resizeHeight < 350)setSidebarHeight(350);
        }
      }
      saveState(open, isFullScreen, sidebarWidth, sidebarHeight, sidebarLeft, sidebarTop);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, isFullScreen, isDrag, isResizing, memoLeft, memoTop],
  );

  const startDrag = useCallback(() => {
    if (isFullScreen) {
      setIsDrag(false);
    } else {
      setIsDrag(true);
    }
    setIsResizing(false);
  }, [isFullScreen]);

  const stopDrag = useCallback(() => {
    setIsDrag(false);
  }, []);

  const startResizing = useCallback(() => {
    if (isFullScreen) {
      setIsResizing(false);
    } else {
      setIsResizing(true);
    }
    setIsDrag(false);
  }, [isFullScreen]);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', trasform);
    if (isDrag)window.addEventListener('mouseup', stopDrag);
    if (isResizing)window.addEventListener('mouseup', stopResizing);

    return () => {
      window.removeEventListener('mousemove', trasform);
      if (isDrag)window.addEventListener('mouseup', stopDrag);
      if (isResizing)window.addEventListener('mouseup', stopResizing);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trasform, stopDrag, stopResizing]);

  const lockSelect = () => {
    document.body.classList.add('TextNoSelect');
  };

  const unlockSelect = () => {
    document.body.classList.remove('TextNoSelect');
  };

  if (locationArray[1] !== 'fascicolo' || isNaN(idFascicolo) || (isAdmin === 'true' && client === 'null')) {
    if (open) toggle();

    return null;
  }

  return (
    <>
      { !open && (
      <IconButton
        className={styles.IconAI}
        onClick={handleOpen}
      >
        <AutoAwesomeTwoToneIcon sx={{
          color: '#fff',
          width: '0.9em',
          height: '0.9em',
        }}
        />
        <Typography p={0} sx={{ display: 'none' }} variant="h5">SirIA</Typography>
      </IconButton>
      )}
      { open && (
      <IconButton
        className={styles.IconAI}
        onClick={handleRestore}
      >
        <RestoreIcon sx={{
          color: '#fff',
          width: '0.9em',
          height: '0.9em',
        }}
        />
        <Typography p={0} sx={{ display: 'none' }} variant="h5">Reset</Typography>
      </IconButton>
      )}
      <Box
        ref={dialogRef}
        className={`${styles.ChatAI}`}
        style={{ top: `${sidebarTop}px`, left: `${sidebarLeft}px`, width: `${sidebarWidth}px`, height: `${sidebarHeight}px`, display: `${open ? '' : 'none'}` }}
      >
        <Box
          className={styles.headerChat}
        >
          <Box
            ref={sidebarRef}
            style={{ width: '100%' }}
            onMouseDown={startDrag}
            onMouseEnter={lockSelect}
            onMouseLeave={unlockSelect}
          >
            <Typography className={styles.TextSiria} p={2} variant="h5">
              <AutoAwesomeTwoToneIcon className={styles.IconSiria} />
              SirIA
            </Typography>
          </Box>
          <IconButton onClick={handleRestore}>
            <Tooltip placement="top" title="Reset" followCursor>
              <RestoreIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={handleFullscreen}>
            {!isFullScreen && (
            <Tooltip placement="top" title="Schermo intero" followCursor>
              <FullscreenIcon />
            </Tooltip>
            )}
            {isFullScreen && (
              <Tooltip placement="top" title="Riduci" followCursor>
                <FullscreenExitIcon />
              </Tooltip>
            )}
          </IconButton>
          <IconButton onClick={handleOpen}>
            <Tooltip placement="top" title="Chiudi" followCursor>
              <CloseTwoTone />
            </Tooltip>
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ height: 'calc(100% - 40px)', position: 'relative' }}>
          {/* <iframe allow="microphone" frameBorder="0" height="100%" src={`http://localhost:4000/?fascicolo_id=${idFascicolo}&access=${JSON.parse(token)}`} width="100%" /> */}
          <iframe frameBorder="0" height="100%" src={`https://ia.ceore.it/?fascicolo_id=${idFascicolo}&access=${JSON.parse(token)}`} width="100%" />
          <Box
            className={styles.chatResize}
            style={{ transition: `${isResizing ? 'none' : '0.5s'}`, cursor: `${isResizing ? 'all-scroll' : 'default'}` }}
            onMouseDown={startResizing}
            onMouseEnter={lockSelect}
            onMouseLeave={unlockSelect}
          >
            <ArrowBackIosIcon style={{ rotate: '-135deg', color: `${isResizing ? '#fff' : '#ffffff80'}` }} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

function saveState(isOpen: boolean, isFullScreen: boolean, sw: number, sh: number, sl: number, st: number) {
  if (!isOpen) return;
  if (isFullScreen) return;
  localStorage.setItem('Siria', `{"W": ${sw || 400}, "H": ${sh || 500}, "L": ${sl || window.innerWidth - 410}, "T": ${st || window.innerHeight - 550}}`);
}
