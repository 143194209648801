import { DownloadForOfflineTwoTone } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';

import { instance } from '../../../../../api/axios';
import type { FascicoloFile } from '../../../../../types/fascicolo-file';
import { useNotification } from '../../../../contexts/notification-context';

export function Download(props: { file: FascicoloFile }) {
  const { notify } = useNotification();
  const fileUrl = props.file.link;
  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    setLoading(true);
    try {
      await downloadFile(fileUrl);
    } catch (err) {
      notify((err as Error)?.message, 'error');
    } finally {
      setLoading(false);
    }
  }, [fileUrl, notify]);

  if (loading) {
    return <CircularProgress size={20} sx={{ m: 1 }} />;
  }

  return (
    <IconButton onClick={handleDownload}>
      <Tooltip placement="top" title="Scarica" followCursor>
        <DownloadForOfflineTwoTone color="secondary" />
      </Tooltip>
    </IconButton>
  );
}

async function getLocalUrl(fileUrl: string) {
  const res = await instance.get(fileUrl, {
    responseType: 'blob',
  });

  if (!res.statusText || res.status >= 400) {
    throw new Error(
      `Errore nel caricamento del file: ${res.status} ${res.statusText}`,
    );
  }

  const fileExt = fileUrl.split('.').pop();

  if (fileExt === 'csv') {
    const BOM = '\uFEFF';

    return window.URL.createObjectURL(new Blob([BOM + res.data], { type: 'text/csv;charset=utf-8' }));
  }

  return window.URL.createObjectURL(new Blob([res.data]));
}

async function downloadFile(fileUrl: string) {
  const url = await getLocalUrl(fileUrl);

  if (url) {
    const fileExt = fileUrl.split('.').pop();

    const link = document.createElement('a');
    link.href = url;
    if (fileExt === 'csv') {
      const csvContent = '1,1,23\ná,é,í,ú,ü,ű,ó,ö,ő,€\n';
      link.setAttribute('href', `data:text/csv;charset=utf-8 ${encodeURI(csvContent)}`);
    }
    link.setAttribute('download', fileUrl.split('/')?.pop() ?? 'file');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
}
