/* eslint-disable radix */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { Box } from '@mui/material';
import type { LocalizationMap } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { type ReactElement } from 'react';
import type { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useLocaleUrl } from '../../../../file-hooks/use-locale-url';
import { PreviewError } from '../../error';
import it_IT from '@react-pdf-viewer/locales/lib/it_IT.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useLocation } from 'react-router';

export function PreviewPdf(props: {
    fileLink: string;
    fileid?: number | null;
    nHeight: string;
  }) {
  const filePath = props.fileLink;

  const { url, error } = useLocaleUrl(filePath);
  const token = localStorage.getItem('token') ?? '';
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const idFascicolo = parseInt(locationArray[2]);
  // const [open, toggle] = useToggleState(false);
  // const handleOpen = useCallback(() => {
  //   toggle();
  // }, [toggle]);

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <Box style={{ padding: '0px 2px', marginLeft: '40px' }}>
              <ShowSearchPopover />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <Zoom />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </Box>
            <Box style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </Box>
            <Box style={{ padding: '0px 2px', display: 'flex', alignItems: 'center' }}>
              <CurrentPageInput /> / <NumberOfPages />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </Box>
            <Box style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </Box>
            <Box style={{ padding: '0px 2px' }}>
              <Download />
            </Box>
            <Box style={{ padding: '0px 2px', marginRight: '5px' }}>
              <Print />
            </Box>
            {/* <Box style={{ padding: '0px 2px', marginRight: '0px' }}>
              <IconButton sx={{ borderRadius: 0 }} onClick={handleOpen}>
                <AutoAwesomeTwoToneIcon sx={{ marginRight: '5px' }} />
                <Typography>SirIA</Typography>
              </IconButton>
            </Box> */}
          </Box>
        );
      }}
    </Toolbar>
  );

  const urlFrame = `https://ia.ceore.it/?fascicolo_id=${idFascicolo}&access=${JSON.parse(token)}&documento_id=${props.fileid}`;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs: any[]) =>
      defaultTabs.splice(0, 2).concat(
        {
          content: <Box style={{ textAlign: 'center', width: '100%' }}><iframe frameBorder="0" height="100%" src={urlFrame} width="100%" /></Box>,
          icon: (
            <AutoAwesomeTwoToneIcon sx={{ fontSize: '16px' }} />
          ),
          title: 'SirIA',
        },
      ),
    renderToolbar,
  });

  const defaultLayoutPluginInstanceNoSiria = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs: any[]) => [
      defaultTabs[0],
      defaultTabs[1],
    ],
    renderToolbar,
  });

  const defaultLayout = process.env.REACT_APP_TYPE === 'development' ? defaultLayoutPluginInstance : defaultLayoutPluginInstanceNoSiria;

  return (
    <>
      <PreviewError error={error} />
      {url && (
        <Box
          style={{
            height: props.nHeight,
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Viewer
            fileUrl={url}
            localization={it_IT as unknown as LocalizationMap}
            plugins={[defaultLayout]}
          />
          {/* <SiriaViewer open={open} toggle={toggle} /> */}
        </Box>
      )}
    </>
  );
}
